import React, { useState, useEffect } from "react";
import Select from "react-select";
import getStrings from "./strings";

function Header({ passValueUp }) {
  const [selectedCity, setSelectedCity] = useState(null);
  const [lang, setLang] = useState("sv");
  const [cities, setCities] = useState([]);
  const [strings, setStrings] = useState(getStrings(lang));
  const [showHeader, setShowHeader] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  useEffect(() => {
    async function fetchCities() {
      let api_response = await fetch("https://api.kamratpostaren.se/calendar")
      api_response = await api_response.json()
      let cities = api_response.map((item) => {
        return item["location"]
      });
      cities = Array.from(new Set(cities)).filter((n) => n);
      cities.sort();
      cities.unshift("allCities");
      const options = cities.map((city) => ({
        value: city,
        label: city === "allCities" ? strings.allCities : city,
      }));
      setCities(options);
    }

    fetchCities();
  }, [strings]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;

      if (scrollTop === 0) {
        setShowHeader(true);
      } else if (scrollTop > lastScrollTop) {
        // Hide header when scrolling down
        setShowHeader(false);
      } else if (scrollTop < lastScrollTop) {
        // Show header when scrolling up
        setShowHeader(true);
      }

      setLastScrollTop(scrollTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

  const handleChange = (selectedOption) => {
    setSelectedCity(selectedOption);
    setVariables("City", selectedOption ? selectedOption.value : null);
  };

  const changeLang = () => {
    const newLang = lang === "sv" ? "eng" : "sv";
    setLang(newLang);
    setStrings(getStrings(newLang));
    setVariables("lang", newLang);
  };

  const setVariables = (varName, value) => {
    let data = {
      varName: varName,
      value: value,
    };
    passValueUp([data]);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff",
      borderColor: "#fcc3c3",
      boxShadow: "none",
      cursor: "pointer",
      borderRadius: "8px",
      "&:hover": {
        borderColor: "#fcc3c3",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#fce2e2",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#fcc3c3" : "#fce2e2",
      color: state.isFocused ? "#ffffff" : "#333333",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#fcc3c3",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#333333",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#999999",
    }),
  };

  return (
    <>
      <div
        className={`bg-[#fcc3c3] px-4 py-2 border border-[#2c2c2c] sm:border-0 sm:px-8 sm:py-1 fixed w-full top-0 z-10 transition-transform duration-300 ${
          showHeader ? "translate-y-0" : "-translate-y-full"
        }`}
      >
        <div className="flex flex-wrap items-center justify-between w-full">
          <div className="flex items-center space-x-1 sm:space-x-2">
            <img
              src="assets/sitebild.png"
              alt="Kamratpostarens logga"
              className="!w-12 !h-12 sm:w-24 sm:h-24 object-contain"
            />
            <h1 className="font-gilbert text-3xl sm:text-3xl font-bold text-black">
              {strings.kamratPostaren}
            </h1>
          </div>

          <div className="flex flex-row w-full justify-between sm:items-center space-y-0 sm:space-x-4 mt-2 sm:mt-0 sm:w-auto">
            <Select
              value={selectedCity}
              onChange={handleChange}
              options={cities}
              placeholder={strings.selectCity}
              isSearchable={false}
              styles={customStyles}
              className="w-36"
            />
            <div className="w-36 sm:w-auto flex justify-center sm:justify-start">
              <button
                type="button"
                onClick={changeLang}
                className="font-atkinson w-full text-center py-1 sm:w-32 bg-white text-black rounded-md border-0 text-lg"
              >
                {strings.changeLang}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
