import React, { useState } from "react";
import getStrings from "./strings";
import Header from "./Header";
// import PhoneSelections from "./PhoneSelections";
import Footer from "./Footer";
import Body from "./Body";
import HowTo from "./HowToEvent";

export default function Site() {
  const [lang, setLang] = useState("sv");
  const [selectedCity, setSelectedCity] = useState("");
  const [strings, setStrings] = useState(getStrings(lang));
  const [popupToggle, setPopupToggle] = useState(false);

  // This function is used to send variables from child modules. Variables are received as an array of dicts containing
  // variable name and variable value
  const passValueUp = (input) => {
    for (var data of input) {
      switch (data["varName"]) {
        case "City":
          setSelectedCity(data["value"]);
          break;
        case "lang":
          setLang(data["value"]);
          setStrings(getStrings(data["value"]));
          break;
        case "popupToggle":
          setPopupToggle(!popupToggle);
          break;
        default:
      }
    }
  };
  return (
    <div>
      <HowTo
        strings={strings}
        popupToggle={popupToggle}
        passValueUp={passValueUp}
      />
      <Header passValueUp={passValueUp} />
      {/* <PhoneSelections passValueUp={passValueUp} /> */}
      <Body
        strings={strings}
        selectedCity={selectedCity}
        passValueUp={passValueUp}
      />
      <Footer strings={strings} />
    </div>
  );
}
