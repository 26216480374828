import React, { useState, useEffect } from "react";

function Body({ strings, selectedCity, passValueUp }) {
  const [data, setData] = useState([]);
  const [sortColumn, setSortColumn] = useState(0);
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    // Fetch events from flask api
    async function fetchData() {
      const api_response = await fetch("https://api.kamratpostaren.se/calendar")
      const calendar_data = await api_response.json()
      const rowsData = calendar_data.map((event) => {
        let title = event["title"]
        if (event["organizer"] && !title.includes(event["organizer"])){
          title = event["organizer"] + " anordnar " + title;
        }
        let date = Date.parse(event["event_time"])
        return {
          timestamp: date.toString(),
          date: Intl.DateTimeFormat("sv-SE", {
            day: "numeric",
            month: "numeric",
            hour: "numeric",
            minute: "numeric",
          }).format(date),
          eventName: title,
          url: event["url"],
          location: event["location"],
          cancelled: event["cancelled"],
        };
      });
      setData(rowsData);
    }

    fetchData();
  }, []);

  // Sort the table data based on the values in the sort column and sort order
  const sortedData = data.sort((rowA, rowB) => {
    let col;
    if (sortColumn === 0) {
      col = "timestamp";
    } else if (sortColumn === 1) {
      col = "eventName";
    } else if (sortColumn === 2) {
      col = "location";
    }
    const cellA = rowA[col];
    const cellB = rowB[col];
    const comparison = cellA.localeCompare(cellB, undefined, { numeric: true });
    return sortOrder === "asc" ? comparison : -comparison;
  });

  // Render the table header row
  const headerRow = (
    <tr>
      <th onClick={() => handleSort(0)}>{strings.timeHead}</th>
      <th onClick={() => handleSort(1)}>{strings.eventHead}</th>
      <th onClick={() => handleSort(2)}>{strings.locationHead}</th>
    </tr>
  );

  const socMedia = (
    <div
      id="socialMedia"
      className="relative pt-8 pb-8 max-w-[800px] m-auto lg:pt-20 lg:right-4 lg:fixed w-[70px] flex flex-nowrap lg:flex-col lg:items-center justify-center"
    >
      <a
        href="https://nyhetsbrev.kamratpostaren.se/subscription/form"
        rel="noopener noreferrer"
        target="_blank"
        title={strings.newsletter}
      >
        <img
          className="min-w-[70px] h-auto sm:w-18 sm:h-18"
          id="mailImg"
          src="assets/mail.png"
          alt={strings.newsletter}
        />
      </a>
      <a
        href="https://www.instagram.com/kamratpostaren/"
        target="_blank"
        rel="noopener noreferrer"
        title="Instagram"
      >
        <img
          className="min-w-[70px] h-auto sm:w-18 sm:h-18"
          id="instaImg"
          src="assets/instagram.png"
          alt="Instagram"
        />
      </a>
      <a
        href="https://radikal.social/@kamratpostaren"
        target="_blank"
        rel="noopener noreferrer"
        title="Mastodon"
      >
        <img
          className="min-w-[70px] h-auto sm:w-18 sm:h-18"
          id="mastodonImg"
          src="assets/mastodon.png"
          alt="Mastodon"
        />
      </a>
      <a
        href="https://bsky.app/profile/kamratpostaren.se"
        target="_blank"
        rel="noopener noreferrer"
        title="Bluesky"
      >
        <img
          className="min-w-[70px] h-auto sm:w-18 sm:h-18"
          id="bskyImg"
          src="assets/bluesky.png"
          alt="Bluesky"
        />
      </a>
      <a
        href="https://twitter.com/kamratpostaren"
        target="_blank"
        rel="noopener noreferrer"
        title="Twitter"
      >
        <img
          className="min-w-[70px] h-auto sm:w-18 sm:h-18"
          id="twitterImg"
          src="assets/twitter.png"
          alt="Twitter"
        />
      </a>
    </div>
  );

  // Render the table data rows
  const dataRows = sortedData.map((row, index) => {
    var tableRow = "";
    if (
      selectedCity.length === 0 ||
      selectedCity === row.location ||
      selectedCity === "allCities"
    ) {
      tableRow = (
        <tr
          cancelled={row.cancelled}
          key={index}
          onClick={() => openLink(row.url)}
          className="!border-0"
        >
          <td className="border border-[#2c2c2c] border-l-0" id="dateCell">
            {row.date}
          </td>
          <td className="border border-[#2c2c2c]">{row.eventName}</td>
          <td className="border border-[#2c2c2c]">{row.location}</td>
        </tr>
      );
    }
    return tableRow;
  });

  const openLink = (url) => {
    window.open(url, "_blank");
  };

  // Handle sorting of the table columns
  const handleSort = (columnIndex) => {
    if (columnIndex === sortColumn) {
      // Toggle the sort order if the same column is clicked again
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // Set the sort column and default to ascending order
      setSortColumn(columnIndex);
      setSortOrder("asc");
    }
  };

  return (
    <div id="mainContent">
      <div className="!hidden lg:!flex" id="socMediaSide">
        {socMedia}
      </div>
      <div className="pt-28 lg:pt-20 lg:pb-20" id="calendarDiv">
        <table className="border border-[#2c2c2c]">
          <thead>{headerRow}</thead>
          <tbody>{dataRows}</tbody>
        </table>
      </div>
      <div
        id="socMediaBottom"
        className="!block border border-y-0 border-x-[#2c2c2c]"
      >
        {socMedia}
      </div>
    </div>
  );
}

export default Body;
