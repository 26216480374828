function getStrings(lang) {
  const svStrings = {
    kamratPostaren: "Kamratpostaren",
    calHeader: "Kommande händelser",
    timeHead: "När",
    eventHead: "Vad",
    locationHead: "Var",
    srcHead: "Källor",
    aboutHead: "Om Kamratpostaren",
    aboutBody1:
      "Kamratpostaren är ett försök att samla vänsterevenemang på ett ställe, för att göra det lättare att hålla koll på vad som händer inom den autonoma vänstern. Tanken är inte att ersätta existerande kalendrar, då försök att skapa en ny gemensam standard ofta splittrar människor mellan fler plattformar.",
    aboutBody2:
      "Kamratpostaren kommer alltså inte att publicera några egna evenemang, utan hämtar endast från redan existerande kalendrar. Detta görs både för att göra det lättare att hitta evenemang och för att ge kalendrarna ytterligare spridning.",
    aboutContact:
      "Tankar, frågor och synpunkter skickas till info@kamratpostaren.se",
    twitter: "Kamratpostaren finns också på ",
    and: "och",
    changeLang: "English",
    selectCity: "Välj stad",
    allCities: "Alla",
    howToButton: "Hur lägger jag upp ett evenemang?",
    howToText:
      "Evenemangen på Kamratpostaren hämtas automatiskt från externa källor, och det är därför inte möjligt att publicera evenemang direkt till Kamratpostaren. Istället behöver du publicera evenemangen på någon av kalendrarna som används som källor. En lista över de kalendrarna finns i sidfoten, och mer information om hur du publicerar ett evenemang finns på respektive hemsida.",
    newsletter: "Nyhetsbrev",
    grafikCred: "Kamratpostarens grafik är designad av ",
  };
  const engStrings = {
    kamratPostaren: "Kamratpostaren",
    calHeader: "Upcoming events",
    timeHead: "When",
    eventHead: "What",
    locationHead: "Where",
    srcHead: "Sources",
    aboutHead: "About Kamratpostaren",
    aboutBody1:
      "Kamratpostaren is an attempt to gather all of Sweden’s leftist events in one place, making it easier to stay updated on what’s happening within the autonomous left. The core idea of Kamratpostaren is not to replace existing calendars; any attempt to create a new common standard will inevitably result in people being divided across even more platforms.",
    aboutBody2:
      "Kamratpostaren will not be posting any events of its own, but will only use events from existing calendar platforms. This is intended to make it easier to find events and to give those platforms additional exposure.",
    aboutContact:
      "Thoughts, questions and ideas can be sent to info@kamratpostaren.se",
    twitter: "Kamratpostaren is also on ",
    and: "and",
    changeLang: "Svenska",
    selectCity: "Select city",
    allCities: "All",
    howToButton: "How do I add an event?",
    howToText:
      "As Kamratpostaren is an event aggregation service, it is not possible to add events to the site directly. Instead you need to add events to one of the source calendars. You can find a list of sources in the site footer, and more information about adding events on each site.",
    newsletter: "Newsletter",
    grafikCred: "Kamratpostaren's graphics by ",
  };
  if (lang === "sv") {
    return svStrings;
  } else {
    return engStrings;
  }
}

export default getStrings;
